import React from 'react'
import logo from '../img/logo.png'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFacebook} from '@fortawesome/free-brands-svg-icons'

const Navbar = () => (
    <header className="navbar navbar-expand navbar-dark flex-column flex-md-row fixed-top bg-dark">
        <div className="container">
            <button className='navbar-toggler' data-target='#collapsingNavbar' data-toggle='collapse' type='button'>
                <span>☰</span>
            </button>
            <div className="navbar-brand">
                <a href="https://www.lesbastides-trabeco.fr/" target="_blank"  rel="noopener noreferrer" className="navbar-item">
                    <figure className="image">
                        <img src={logo}  alt="Logo Les Bastides" className="logo"/>
                    </figure>
                </a>
            </div>
            <ul className='nav navbar-nav ml-auto '>
                <li className='nav-item p-2 text-light'>
                    Rejoignez nous sur :
                </li>
                <li className='nav-item p-2'>
                    <a href="https://fr-fr.facebook.com/lesbastidestrabeconice/"  target="_blank"  rel="noopener noreferrer"  title="Aimez nous sur Facebook">
                        <FontAwesomeIcon icon={faFacebook}/>
                    </a>
                </li>
            </ul>
        </div>
    </header>
)

export default Navbar
